::selection {
  background: #111111;
}

::-moz-selection {
  background: #111111;
}

body * {
  font-family: "Roboto", sans-serif;
}

body {
  background: #111111;
}

iframe {
  width: 100%;
}

.navbar-toggler-icon {
  background-image: url(/public/img/menu.svg);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background-size: 100% 100%;
}

.banner-section-ad img, .sidebar-banner img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sidebar-news .banner-section-ad {
  margin-bottom: 2rem;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 0;
}

.login-reg-buttons {
  display: flex;
  flex-direction: row;
}

.login-reg-buttons p {
  margin: 0 2px;
}

.login-reg-buttons a {
  color: inherit;
  text-decoration: none;
}

.main-bg {
  background: #e30613;
}

.main_nav {
  background: #9f0613;
  padding: 0;
}

.main_nav .nav-link {
  padding: 10px;
}

.main_nav .nav-link:hover {
  background: black;
  color: white;
}

.socials {
  background: #1a2026;
  width: 20%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top-left-radius: -10px;
  position: relative;
  overflow: hidden;
}

.main_nav a {
  color: white;
  text-transform: uppercase;
}

.dropdown-menu.show {
  background: #9f0613;
  border: none;
  padding-top: 0;
  margin-top: 0;
  border-radius: 0;
}
.navbar-brand img {
  max-width: 90%;
}
.topbar {
  padding-top: 10px;
  padding-bottom: 0;
}

a.dropdown-item {
  padding-left: 10px;
}

a.dropdown-item:hover {
  background: black;
  color: white;
}

.register_button p {
  color: #000000 !important;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 0.75em;
}

.register_button {
  background: #ffbb1a;
  text-decoration: none !important;
}

.login-reg-buttons p {
  padding: 3px 20px;
  font-size: 12px;
  border-radius: 3px;
}

.login-reg-buttons .login_button {
  background: #ffffff;
  color: #000000;
}

.login-reg-buttons .login_button {
  color: #33161e;
}

.news-title-container {
  margin: 30px 0;
}

.news-title h2:before,
.news-meta p:before,
.footer-title::before,
.post-title-block ul li::before,
.sidebar-recent-title::before {
  border-color: #a00;
  background: #e30613;
}

.news-title h2:before {
  content: "";
  width: 15%;
  height: 100%;
  border-left: 8px solid;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  z-index: -1;
  right: 0;
  margin: 0 auto;
  color: #e30613;
}

.news-meta p:before,
.post-title-block ul li::before,
.sidebar-recent-title:before {
  content: "";
  width: 100%;
  height: 100%;
  border-left: 8px solid;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  z-index: -1;
  color: #e30613;
}

.news-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 50px 0; */
}

.news-title h2 {
  font-size: 1.5rem;
  margin: 0;
  color: white;
}

.news-title:before {
  content: "";
  background: white;
  width: 25%;
  left: 150px;
  height: 1px;
  position: absolute;
}

.news-title:after {
  content: "";
  background: white;
  width: 25%;
  right: 150px;
  height: 1px;
  position: absolute;
}

.news {
  background: #111111;
}

.news a {
  height: 100%;
}

.news img {
  object-fit: cover;
}

.news-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
  z-index: 1;
}

.news-content-relative {
  position: relative;
  padding: 20px 0;
}

.news-meta {
  color: white;
  position: relative;
  padding: 0 5px;
}

.news-meta p {
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  display: table;
  padding: 0 5px;
}

table {
  color: white !important;
}
.category-title h2:before {
  width: 20%;
}
.news-title-inner a {
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p.slide-play-icon:before {
  content: "\f144";
  font-size: 50px;
  color: #e30613;
}

p.slide-play-icon {
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 20px;
  top: 20px;
}

.slideshow__thumbs p.slide-play-icon {
  top: 5px;
}

.slideshow__thumbs p.slide-play-icon:before {
  font-size: 20px;
}

.betting-title h2:before {
  width: 20%;
}

.betting {
  padding-bottom: 50px;
  background: #111111;
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  z-index: 9999;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 50px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 50px;
}

.big-item {
  display: flex;
  width: 100%;
  position: relative;
}

.medium-item {
  width: 80%;
  display: flex;
  position: relative;
}

.small-items {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
}

.small-items .grid-item a {
  font-size: 13px;
  line-height: 1.2;
}

.small-items .grid-item {
  position: relative;
  line-height: 1.2;
}

.post_slider .news-title-inner a {
  font-size: 12px;
}

.post_slider .swiper-slide img {
  height: 250px;
  object-fit: cover;
}

.overlay-category,
.overlay-img {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.overlay-img img {
  width: 100%;
  height: 100%;
}
.no-banner-section {
  margin-top: 3rem;
}

.overlay-category img {
  height: 350px;
  object-fit: cover;
  width: 100%;
}

.sidebar-news .swiper-slide::after,
.slideshow__gallery-wrap .swiper-slide::after,
.overlay-category::after,
.overlay-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0.5)),
    color-stop(100%, rgba(0, 0, 0, 0.5))
  );
  background: -webkit-linear-gradient(
    top,
    rgb(255 255 255 / 0%) 0%,
    rgb(0 0 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgb(255 255 255 / 0%) 0%,
    rgb(0 0 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(255 255 255 / 0%) 0%,
    rgb(0 0 0) 100%
  );
  background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00000000', GradientType=0);
}

.img-gradient img {
  display: block;
}

.betting-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 80%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  color: black;
  opacity: 1 !important;
}

footer {
  background: linear-gradient(45deg, black, transparent),
    url(/public/img/bg-footer-sport.jpg);
  color: white;
  display: table;
  width: 100%;
  background: linear-gradient(0deg, rgb(0 0 0 / 70%), rgb(0 0 0 / 70%)),
    url(/public/img/bg-footer-sport.jpg);
  background-position: center center;
  background-size: cover;
}

footer a {
  color: white !important;
}

.footer-title {
  display: block;
  position: relative;
  z-index: 1;
}

.footer-title:before {
  content: "";
  width: 100%;
  height: 100%;
  border-left: 8px solid;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  z-index: -1;
  color: #e30613;
}

footer ul,
footer p {
  padding: 20px 0;
}

.footer-socials img {
  width: 70px;
}

.footer-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-images img {
  height: 30px;
  object-fit: scale-down;
}

/* post */

section {
  width: 100%;
  float: left;
  position: relative;
}

.post-content-section,
.category-posts {
  margin-bottom: 3rem;
}

.post-title-block {
  padding: 100px 0;
}

.post-title-block h1 {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  text-transform: capitalize;
}

.post-title-block li {
  font-size: 20px;
  color: #fff;
}

.post-title-block ul li {
  position: relative;
}

.post-title-block ul li,
.sidebar-recent-title {
  z-index: 1;
  padding: 0 10px;
  text-transform: uppercase;
  position: relative;
}

.related-news {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 20px;
  align-items: center;
  justify-content: center;
}

.media-body-left:before {
  position: absolute;
  left: -30px;
  top: 30%;
  content: "\f053";
  font: normal normal normal 14px/1 FontAwesome;
}

.media-body-right:after {
  position: absolute;
  right: -30px;
  top: 30%;
  content: "\f054";
  font: normal normal normal 14px/1 FontAwesome;
}

.media-body {
  position: relative;
}

.related-news .media h4 a {
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

.burger_menu button {
  float: right;
}
.burger_menu {
  width: 100%;
}

.left-related,
.right-related {
  width: 45%;
}

.right-related {
  text-align: right;
}

.image-block {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.footer-link {
  float: left;
  width: 100%;
  background: #222222;
  text-align: center;
  padding: 30px;
}

.footer-link a {
  color: #a9fd00;
  font-size: 18px;
  text-transform: uppercase;
}

.banner-section {
  height: 210px;
  margin-bottom: 20px;
}

h1.single-post-title {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #e30613;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

span.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #e30613 !important;
}

.post-title-block {
  padding: 100px 0;
}

.post-title-block li {
  font-size: 20px;
  color: #fff;
  display: inline;
}

blockquote {
  width: 100%;
  margin: 20px auto;
  font-style: italic;
  color: #000000;
  padding: 1.2em 30px 1.2em 50px;
  border-left: 8px solid #e30613;
  line-height: 1.6;
  position: relative;
  background: #ededed;
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #e30613;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: "";
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

article {
  padding: 20px 0;
  color: white;
}

article img {
  max-width: 100%;
  height: auto;
  margin: 10px auto;
  display: block;
}

.sidebar-post-img {
  height: 100px;
  width: 150px;
  object-fit: cover;
}

.newest-post-sidebar .media-body a {
  color: white;
}

.sidebar-news .media {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee29;
}

.sidebar-news .media:last-child {
  border: none;
}

.sidebar-recent-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.media-left {
  padding-right: 10px;
}

.sidebar-news {
  color: white;
}

#scrollTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #e30613;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

#scrollTop:hover {
  background-color: #555;
}

@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@-moz-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

.scroll-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  z-index: 99;
}

.scroll-btn > * {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #ffffff;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  color: #ffffff;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 20px;
  height: 35px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 23px;
}

.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: white;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}

.icon-bar {
  position: sticky;
  top: 0;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.slideshow {
  display: flex;
  height: 80vh;
  width: 80vw;
  max-width: 1240px;
  min-height: 400px;
  max-height: 700px;
  position: relative;
  padding: 20px;
}

.slideshow__gallery {
  height: 100%;
  width: calc(70% - 20px);
  margin: 0;
}

.slideshow__gallery-wrap {
  will-change: transform;
  transition-timing-function: cubic-bezier(0, 0, 0.3, 0.98);
  transform: translate3d(0, 0, 0);
}

.slideshow__gallery-wrap .slideshow__gallery-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 32px;
  color: #fff;
  letter-spacing: 3.2px;
  transform: translate3d(0, 0, 0);
  will-change: font-size;
  transition: font-size 0.3s ease;
}

.slideshow__gallery-wrap .slideshow__gallery-slide.active {
  font-size: 36px;
}

.slideshow__thumbs {
  height: 100%;
  width: 30%;
}

.slideshow__thumbs-wrap {
  will-change: transform;
  transition-timing-function: cubic-bezier(0, 0, 0.3, 0.98);
  transform: translate3d(0, 0, 0);
}

.slideshow__thumbs-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  letter-spacing: 2px;
  will-change: font-size;
  transition: font-size 0.3s ease;
}

.slideshow__thumbs-slide.active {
  font-size: 26px;
}

.js-thumbs .news-content-relative {
  padding: 0;
}

.js-thumbs .news-meta p {
  margin: 0;
  font-size: 10px;
}

.js-thumbs .news-title-inner-videos a {
  font-size: 14px;
  line-height: 1.2 !important;
  color: white !important;
  text-decoration: none i !important;
}

.js-gallery .news-content {
  left: 30px;
}

.news-title-inner-videos {
  line-height: 1.2;
}

.slideshow__gallery .news-meta p {
  letter-spacing: 1px;
  font-size: 16px;
}

.slideshow__gallery .news-title-inner {
  font-size: 20px;
  line-height: 1.2;
}
.show-786-bellow {
  display: none;
}

@media screen and (max-width: 1024px) {
  .slideshow {
    width: 100%;
  }

  .small-items .grid-item .news-content {
    padding: 10px;
  }

  .banner-section {
    background-size: contain !important;
    background-repeat: no-repeat;
    height: 160px;
  }
  .related-news .media h4 {
    line-height: 1;
  }

  .newest-post-sidebar .media-body a {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .table {
    overflow-x: auto;
    display: block;
  }
  .ipad-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }
  .hide-ipad-bellow {
    display: none !important;
  }
  .show-ipad {
    display: flex !important;
  }
  .post_slider .swiper-slide img {
    height: auto;
    width: 100%;
  }
  .banner-section {
    background-size: cover !important;
  }
  .icon-bar {
    position: relative;
    width: 100%;
  }

  .soc-icons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .scroll-btn {
    display: none;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
  }
  .show-786-bellow {
    display: flex;
  }

  .hide-786-bellow {
    display: none;
  }

  .news .big-item,
  .news .medium-item,
  .small-items .grid-item {
    width: 50%;
    padding: 1rem;
  }

  .small-items {
    flex-direction: row;
    width: 100%;
  }
  .news-title:before {
    width: 30%;
    left: 20px;
  }
  .news-title:after {
    width: 30%;
    right: 20px;
  }
  .news-title h2:before {
    width: 25%;
  }
  .slideshow {
    height: auto;
    min-height: 350px;
    max-height: 350px;
  }
  .js-thumbs .news-title-inner-videos a {
    font-size: 10px;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .news .news-content {
    padding: 10px;
    bottom: 30px;
  }
  .small-items .grid-item a {
    font-size: 16px;
  }
  .navbar-brand {
    width: 250px;
    max-width: 250px;
  }

  .align-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topbar {
    padding: 10px 0 !important;
  }
  .news-title-inner-videos {
    margin: 10px 0;
  }

  .video-thumb-img {
    object-fit: cover;
    height: 120px;
  }
  .slideshow__thumbs p.slide-play-icon {
    z-index: 9;
  }
  .overlay-category img {
    height: auto;
  }
  .category-posts {
    margin-bottom: 0;
  }
  .slideshow {
    padding: 0 20px;
  }
}

@media screen and (max-width: 650px) {
  .news {
    flex-direction: column;
  }
  .news .big-item,
  .news .medium-item,
  .small-items .grid-item {
    width: 100%;
  }

  .slideshow__gallery-wrap .news-title-inner a {
    font-size: 12px;
    display: block;
    line-height: 1.5;
  }

  .small-items {
    flex-direction: column;
  }

  .news-title h2:before {
    width: 45%;
  }
  .news-title:before,
  .news-title:after {
    width: 20%;
  }

  .news-title h2 {
    font-size: 1.3rem;
  }

  .slideshow {
    flex-direction: column;
  }
  .slideshow__gallery,
  .slideshow__thumbs {
    width: 100%;
    margin: 1rem 0;
  }
  .slideshow {
    min-height: 450px;
    max-height: 450px;
  }
  .slideshow__thumbs-wrap .col-md-6 {
    padding: 0 15px !important;
  }
}

/*slider switch css */
.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch-wrapper em {
  margin-left: 10px;
  font-size: 1rem;
}

.theme-switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  margin: 0;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 13px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 13px;
}

.theme-switch-wrapper .fa {
  margin: 0 5px;
}

input:checked + .slider {
  background-color: #000;
}

input:checked + .slider:before {
  transform: translateX(26px);
  left: -4px;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body.light {
  background: #ffffff;
}

body.light .betting {
  background: #ffffff;
}

body.light .js-thumbs .news-title-inner-videos a {
  color: #000000 !important;
}

body.light h1.single-post-title {
  color: #000;
}

body.light article {
  color: #000;
}

body.light .newest-post-sidebar .media-body a {
  color: #000;
}

body.light .icon-bar a {
  color: #000;
}

body.light .icon-bar a:hover {
  background-color: #fff;
}

body.light .prev_post_url a,
body.light .next_post_url a {
  color: #000 !important;
}

body.light .news {
  background: #ffffff;
}

body.light .navbar-toggler-icon {
  background: url(/public/img/white-menu.svg);
  background-size: cover;
}
.navbar-collapse.show .theme-switch-wrapper {
  margin: 10px 0;
}

.baner-img span {
  position: absolute;
  left: 15%;
  color: #ad1a27;
  top: 25%;
  line-height: 1;
  font-size: 8rem;
  font-family: 'Anton', sans-serif;
}

.baner-img {
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
  cursor: pointer;
}

.baner-img img {
  max-width: 100%;
  height: 280px;
}

@media screen and (max-width: 1440px) {
  .baner-img img {
      max-width: 100%;
      height: 210px;
  }
  .baner-img span {
    font-size: 7rem;
}
}

@media screen and (max-width: 1024px) {
  .baner-img img {
      max-width: 100%;
      height: 151px;
  }
  .baner-img span {
    font-size: 5rem;
}
}

@media screen and (max-width: 767px) {
  .baner-img img {
      max-width: 100%;
      height: auto;
  }
  .baner-img span {
    font-size: 2rem;
}

}